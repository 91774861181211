import mitt from 'mitt';

/**
 * Attach global mitt() properties for Vue. The eventbus has
 * changed in Vue 3 and this is a workaround. We should
 * update our code to use the new eventbus syntax.
 *
 * @see https://vuejs.org/guide/components/events.html
 * @type {Emitter}
 */
export default {
    install(app) {

        app.config.globalProperties.Bus = mitt();

        app.mixin({
            methods: {
                emit(event, data) {
                    this.Bus.emit(event, data);
                }
            }
        });

        //global emit shortcut
        window.emit = function(eventName, data) {
            return app.config.globalProperties.Bus.emit(eventName, data)
        };
    }
}
